import './App.css';

function App() {
  return (
    <div className="App">
      <p>Portfolio website at: <a href="https://briannamcdonald.me/portfolio-website">briannamcdonald.me/portfolio-website</a></p>
    </div>
  );
}

export default App;
